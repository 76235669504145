import { Tabs } from 'antd';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { getProductList } from 'utils/api';
import logo from '../../assets/images/logo.svg';
import xiaozhi from '../../assets/images/xiaozhi-avatar.png';

export const Payment: FC = () => {
  const items = [
    {
      key: '1',
      label: 'VIP',
      children: `Content of Tab Pane 1`,
    },
    {
      key: '2',
      label: '次卡',
      children: `Content of Tab Pane 2`,
    },
  ];

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <div className="">
      <div>JustAI 会员订阅</div>
      <Tabs defaultActiveKey="1" centered items={items} />
    </div>
  );
};
