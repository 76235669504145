import axios from 'axios';

export const post = (url: string, body: any): Promise<any> => {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        'Session-Id':
          'Z0FBQUFBQmtQcjdlR1hNcWxIYk4zd0dCSE9LZ0lleS1uWkdQSk5kQnVRUmtLVWxMVFA4cjZKZUl6VUZELXE2OERKUS13OWU5N18xS2ZLdVZuNnpqaldsS01kZ255SnNjVVVLNU04NXFNZ0wyZllWdjRtMl96YjdMUFZGT3ltOWEwbzV3ZFNTY1NMbngtbUxaQWNQVjkwZ19ZNGRpZzVtZlF3PT0=',
      },
    })
    .then(res => {
      return res;
    });
};
