import { FC } from 'react';
import blurM from '../../assets/images/blur-mobile.svg';
import blur from '../../assets/images/blur.svg';
import dot from '../../assets/images/dot.svg';
import gongzhonghao from '../../assets/images/gongzhonghao.png';
import headerM from '../../assets/images/header-mobile.svg';
import header from '../../assets/images/header.svg';
import logo from '../../assets/images/logo-text.svg';
import program from '../../assets/images/mini-program.png';
import phone from '../../assets/images/phone.png';
import screenM from '../../assets/images/screen-mobile.png';
import screen from '../../assets/images/screen.png';

export const Home: FC = () => {
  return (
    <div>
      <img
        src={logo}
        className="mt-[3vw] ml-[11vw] w-[159px] mobile:mt-[24px] mobile:ml-[24px] mobile:w-[80px] md:w-[120px] lg:mt-[48px] lg:ml-[200px]"
      />
      <div className="mt-[73px] text-center mobile:hidden">
        <div className="inline-block rounded-[30px] bg-light px-[24px] py-[16px] text-center">
          <div className="inline text-[12px] font-medium text-black60">
            Now We Are Here Using AI Technology
          </div>
          <div className="inline pl-[5px] text-[16px] font-medium">🎉</div>
        </div>
      </div>
      <div className="mt-[94px] flex justify-center mobile:hidden">
        <img src={header} className="w-[948px] sm:w-[600px] md:w-[710px]" />
      </div>
      <div className="mt-[60px] ml-[24px] hidden mobile:block">
        <img src={headerM} className="w-[269px]" />
      </div>
      <div className="mt-[24px] text-center text-[24px] font-normal leading-[42px] text-black80 mobile:mt-[16px] mobile:ml-[24px] mobile:text-left mobile:text-[12px] mobile:leading-[20px] sm:text-[16px] md:text-[18px]">
        创作文案，JustAI 最懂你！智能助手，省时省心。
      </div>
      <div className="flex justify-center text-[24px] font-normal leading-[42px] text-black80 mobile:ml-[24px] mobile:block mobile:text-left mobile:text-[12px] mobile:leading-[20px] sm:text-[16px] md:text-[18px]">
        <div>定制化独具匠心的文案场景，释放无限创意。</div>
        <div>轻松高效，打造独特文采。</div>
      </div>
      <div className="text-center text-[24px] font-normal leading-[42px] text-black80 mobile:ml-[24px] mobile:text-left mobile:text-[12px] mobile:leading-[20px] sm:text-[16px] md:text-[18px]">
        选择JustAI，让你的文案如虎添翼！
      </div>
      <div className="relative pt-[19vw] mobile:pt-[174px] lg:pt-[326px]">
        <div className="absolute top-0 left-[168px] right-[168px] mobile:hidden">
          <img src={blur} className="w-full" />
        </div>
        <div className="absolute -top-[300px] hidden w-full mobile:block">
          <img src={blurM} className="w-full" />
        </div>
        <div className="relative mx-[28px] rounded-[48px] bg-black py-[6vw] px-[10vw] mobile:mx-0 mobile:h-[439px] mobile:rounded-none mobile:p-0 lg:py-[112px] lg:px-[172px]">
          <div className="z-10 mobile:absolute mobile:bottom-0 mobile:left-0 mobile:w-full">
            <div className="text-[64px] font-semibold text-white mobile:text-center mobile:text-[24px] mobile:font-bold sm:text-[36px] md:text-[48px]">
              JustAI文案助手
            </div>
            <div className="mt-[20px] text-[32px] font-normal text-white mobile:mb-[55px] mobile:mt-[16px] mobile:text-center mobile:text-[16px] sm:text-[18px] md:text-[24px]">
              也许是你用过最好用的中文文案生成工具
            </div>
          </div>
          <div className="relative z-[1] mt-[36px] flex gap-[54px] mobile:hidden">
            <div>
              <img src={program} className="h-[14vw] max-h-[240px] w-[14vw] max-w-[240px]" />
              <div className="mt-[28px] text-center text-[20px] font-normal text-white60 sm:text-[12px] md:text-[15px]">
                扫码免费体验小程序
              </div>
            </div>
            <div>
              <img src={gongzhonghao} className="h-[14vw] max-h-[240px] w-[14vw] max-w-[240px]" />
              <div className="mt-[28px] text-center text-[20px] font-normal text-white60 sm:text-[12px] md:text-[15px]">
                扫码关注公众号
              </div>
            </div>
          </div>
          <img src={screen} className="absolute right-0 bottom-0 w-[90%] rounded-br-[48px]" />
          <img
            src={screenM}
            className="absolute right-0 bottom-0 w-[90%] rounded-br-[48px] mobile:w-full mobile:rounded-none"
          />
          <img
            src={phone}
            className="absolute right-[13vw] -top-[14vw] w-[24vw] max-w-[420px] mobile:relative mobile:left-1/2 mobile:-top-[130px] mobile:w-[200px] mobile:-translate-x-1/2 lg:right-[220px] lg:-top-[240px]"
          />
        </div>
      </div>
      <div className="my-[94px] flex items-center justify-center gap-[40px] mobile:hidden sm:scale-50 md:scale-75">
        <img src={dot} />
        <div className="text-[36px] font-normal text-gray">JustAI网页版 敬请期待</div>
        <img src={dot} className="rotate-180" />
      </div>
      <div className="hidden w-full text-center mobile:inline-block mobile:bg-gray3">
        <div className="mt-[43px]">
          <img src={program} className="h-[200px] w-[200px] rounded-[12px]" />
          <div className="mt-[12px] text-[16px] font-normal text-black60">扫码免费体验小程序</div>
        </div>
        <div className="mt-[24px] mb-[43px]">
          <img src={gongzhonghao} className="h-[200px] w-[200px] rounded-[12px]" />
          <div className="mt-[12px] text-[16px] font-normal text-black60">扫码关注公众号</div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-[50px] p-[20px] mobile:flex-col mobile:items-end mobile:gap-[10px] mobile:bg-gray3">
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noreferrer"
          className="text-[12px] leading-[12px] text-black60 no-underline"
        >
          粤ICP备2023029047号-1
        </a>
        <div className="item-center flex text-[12px] text-black60">
          <span className="mr-[10px]">加思特(侯马)智能科技有限公司</span>
          <span>联系电话：</span>
          <a href="tel:+8616620792858">16620792858</a>
        </div>
      </div>
    </div>
  );
};
