import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home/index';
import { Payment } from './pages/payment';

export const App: FC = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="payment" element={<Payment />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
