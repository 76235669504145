import { post } from './request';

export const getProductList = (): Promise<any> => {
  return post('https://justailab.com/order/get_product_list', {
    items_per_page: 30,
    page_number: 1,
  })
    .then(res => {
      console.log(res);
    })
    .catch(error => {
      console.log(error);
    });
};
